import React, { memo, useCallback, useState } from "react";
import { FormProvider } from "./context/FormContext";

export default memo(function PaymentForm({
  children,
  createPaymentRequest,
  cardTokenizeResponseReceived,
  applicationId,
  locationId,
  overrides = {},
  onMethodsChange,
  onReadyStatusChange,
  ...props
}) {
  const [disabled, setDisabled] = useState(true);

  const _onReadyStatusChange = useCallback(
    (ready) => {
      if (onReadyStatusChange) {
        onReadyStatusChange(ready);
      }
      setDisabled(!ready);
    },
    [onReadyStatusChange]
  );
  return (
    <>
      <FormProvider
        createPaymentRequest={createPaymentRequest}
        cardTokenizeResponseReceived={cardTokenizeResponseReceived}
        applicationId={applicationId}
        locationId={locationId}
        overrides={overrides}
        onMethodsChange={onMethodsChange}
        onReadyStatusChange={_onReadyStatusChange}
      >
        {disabled && (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
        <form
          id="payment-form"
          {...props}
          style={{
            display: disabled ? "none" : "",
          }}
        >
          {children}
        </form>
      </FormProvider>
    </>
  );
});
