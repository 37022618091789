import React, { memo, useCallback, useEffect } from "react";
import * as Square from "@square/web-sdk";

export const FormContext = React.createContext({
  form: {
    createPaymentRequest: () => {},
    cardTokenizeResponseReceived: () => {},
    applicationId: "",
    locationId: "",
    inicializedMethods: [],
    overrides: {},
    onMethodsChange: () => {},
    addReadyCount: () => {},
    decreaseReadyCount: () => {},
    onReadyStatusChange: () => {},
  },
});

export const FormProvider = memo(
  ({
    children,
    createPaymentRequest,
    cardTokenizeResponseReceived,
    applicationId,
    locationId,
    overrides = {},
    onMethodsChange,
    onReadyStatusChange = () => {},
  }) => {
    const [inicializedMethods, setInicializedMethods] = React.useState({});
    const [payments, setPayments] = React.useState(null);
    const [pendingCount, setPendingCount] = React.useState(0);
    const isReady = pendingCount === 0;

    const addMethod = useCallback((method) => {
      console.log("addMethod", method);
      setInicializedMethods((methods) => ({ ...methods, [method]: true }));
    }, []);

    const removeMethod = useCallback((method) => {
      console.log("removeMethod", method);
      setInicializedMethods((methods) => ({ ...methods, [method]: false }));
    }, []);

    const isMethodInicialized = useCallback(
      (method) => {
        return inicializedMethods[method] || false;
      },
      [inicializedMethods]
    );

    const addPendingCount = useCallback(() => {
      setPendingCount((count) => count + 1);
    }, []);

    const decreasePendingCount = useCallback(() => {
      setPendingCount((count) => count - 1);
    }, []);

    useEffect(() => {
      if (onMethodsChange) {
        onMethodsChange(inicializedMethods);
      }
    }, [inicializedMethods, onMethodsChange]);

    useEffect(() => {
      onReadyStatusChange(isReady);
    }, [isReady, onReadyStatusChange]);

    const loadPayment = useCallback(
      async (signal) => {
        console.log(
          "loadPayment ",
          applicationId,
          locationId,
          "overrides",
          overrides
        );
        try {
          addPendingCount();
          const res = await Square.payments(
            applicationId,
            locationId,
            overrides
          );

          if (res === null) {
            throw new Error("Square Web Payments SDK failed to load");
          }

          if (signal?.aborted) {
            decreasePendingCount();
            return;
          }

          console.log("loadPayment SUCCESS");
          decreasePendingCount();
          setPayments(res);
        } catch (error) {
          console.log("loadPayment ERROR");
          decreasePendingCount();
          console.error(error);
        }
      },
      [applicationId, locationId, setPayments, addPendingCount, decreasePendingCount]
    );

    useEffect(() => {
      const abortController = new AbortController();
      const { signal } = abortController;

      if (applicationId && locationId) {
        console.log("useEffect FormProvider");
        loadPayment(signal);
      }

      return () => {
        abortController.abort();
      };
    }, [applicationId, locationId, loadPayment]);

    useEffect(() => {
      setInicializedMethods({});
    }, []);

    return (
      <FormContext.Provider
        value={{
          createPaymentRequest,
          cardTokenizeResponseReceived,
          applicationId,
          locationId,
          addMethod,
          removeMethod,
          isMethodInicialized,
          payments,
          inicializedMethods,
          addPendingCount,
          decreasePendingCount,
        }}
      >
        {children}
      </FormContext.Provider>
    );
  }
);

export const useForm = () => {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};
