import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import StripePaymentElement from "./components/StripePaymentElement";
import PaymentForm from "./components/Square/PaymentForm";
import ApplePay from "./components/Square/ApplePay";
import { useForm } from "./components/Square/context/FormContext";
import { keyframes, styled } from "../stitches.config";
import { Nav, NavItem, NavLink, Navbar, TabContent, TabPane } from "reactstrap";
import { Tab } from "bootstrap";
import GooglePay from "./components/Square/GooglePay";
import stripeIcon from '../img/visa_master.png';
import squareApple from '../img/square_apple.png';
import squareAppleInvert from '../img/square_apple_invert.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const paymentMethodsIcon = {
  stripe: stripeIcon,
  square_apple: squareApple,
  square_apple_invert: squareAppleInvert
}

export const PayButton = styled("button", {
  backgroundColor: "#006aff",
  borderRadius: 5,
  boxShadow: 1,
  color: "#fff",
  cursor: "pointer",
  borderStyle: "none",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  outline: "none",
  padding: 12,
  userSelect: "none",
  width: "100%",
  "&:active": {
    backgroundColor: "rgb(0, 85, 204)",
  },
  "&:disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    color: "rgba(0, 0, 0, 0.3)",
    cursor: "not-allowed",
  },
});

const MobileCheckoutApp = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeClicked, setStripeClicked] = useState(false);
  const [total, setTotal] = useState(null);
  const [textos, setTextos] = useState({});
  const containerRef = useRef(null);
  const [isAppleIncompatible, setIsAppleIncompatible] = useState(false);
  const [isGoogleIncompatible, setIsGoogleIncompatible] = useState(false);
  const showSquare = !isAppleIncompatible // || !isGoogleIncompatible;
  const [selectedTab, setSelectedTab] = useState("card");

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "night",
      variables: {
        colorText: "#fff",
        colorPrimary: "#958063",
        fontSizeBase: "16px",
        fontWeightNormal: "900",
      },
      fontFamily: "Open Sans, Arial, sans-serif",
      rules: {
        ".Input::placeholder": {
          color: "#958063",
        },
        ".Dropdown": {
          color: "#fff",
        },
        ".DropdownItem": {
          color: "#fff",
        },
        ".Label": {
          color: "#958063",
        },
      },
    },
  };

  const urlGlobal = process.env.REACT_APP_BACKEND_ENDPOINT;
  const postMessage = useCallback((data, targetOrigin) => {
    const fn = window.postMessageNew
      ? window.postMessageNew
      : window.ReactNativeWebView
      ? window.ReactNativeWebView.postMessage
      : window.postMessage;
    try {
      fn(JSON.stringify(data), targetOrigin);
    } catch (error) {
      window.postMessage(JSON.stringify(data), targetOrigin);
    }
  }, []);

  const onParentMessage = useCallback(function (event) {
    event = event.detail || event;
    if (event.origin === "") {
      // Events from app
      const data = event && event.data && JSON.parse(event.data);
      if (data.type === "data") {
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        }
        if (data.textos) {
          setTextos(data.textos);
        }
        if (data.total) {
          setTotal(data.total);
        }
      }
    }
  }, []);

  //const logapp = (message) => postMessage({ type: 'log', message }, "*");
  //console.log = (message) => postMessage({type: 'log', message}, "*");

  useEffect(() => {
    // subscribe for recive parent data messages
    window.addEventListener("messageRN", onParentMessage);
    window.addEventListener("message", onParentMessage);

    postMessage({ type: "loaded" });

    return () => {
      // unsubscribe for recive parent data messages
      window.removeEventListener("messageRN", onParentMessage);
      window.removeEventListener("message", onParentMessage);
    };
  }, [onParentMessage, postMessage]);

  useEffect(() => {
    if (!containerRef.current) return;

    const interval = setInterval(() => {
      postMessage({
        type: "height",
        height: containerRef.current.scrollHeight,
      });
    }, 500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [containerRef.current?.scrollHeight, postMessage]);

  const onSuccess = (paymentId, payed) => {
    // send message to parent
    postMessage(
      { type: "payment", payed, success: true, paymentId, err: null },
      "*"
    );
  };

  const onError = (err) => {
    // send message to parent
    postMessage({ type: "payment", success: false, paymentId: null, err }, "*");
  };

  const onReadyStripe = () => {
    postMessage({ type: "ready", success: true });
  };

  const onSquareReadyStatusChange = useCallback(
    (isReady) => {
      postMessage({ type: "square-ready", success: isReady });
    },
    [postMessage]
  );

  const createPaymentRequest = useCallback(
    () => ({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: `${total}`,
        label: "Total",
      },
    }),
    [total]
  );

  const cardTokenizeResponseReceived = ({ token }) => {
    onSuccess(token, "square");
  };

  return (
    <>
      <div
        className="h-100 w-100 pb-4 px-4 text-light text-center d-flex flex-column justify-content-center"
        ref={containerRef}
      >
        <Nav
          tabs
          fill
          card
          role="tablist"
          style={{
            display: showSquare ? "" : "none",
          }}
          className="pt-0 mt-0"
        >
          <NavItem>
            <NavLink
              active={selectedTab == "card"}
              onClick={() => setSelectedTab("card")}
            >
              <img 
                src={paymentMethodsIcon.stripe}
                alt="Credit Card" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={selectedTab == "wallet"}
              onClick={() => setSelectedTab("wallet")}
            >
              <img 
                src={selectedTab == "wallet" ? paymentMethodsIcon.square_apple : paymentMethodsIcon.square_apple_invert}
                alt="Wallets" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={showSquare ? selectedTab : "card"}>
          <TabPane tabId="wallet" className="pt-5">
            {total && (
              <PaymentForm
                key={`payment-form-${total}`}
                applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
                locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                createPaymentRequest={createPaymentRequest}
                cardTokenizeResponseReceived={cardTokenizeResponseReceived}
                onReadyStatusChange={onSquareReadyStatusChange}
              >
                <ApplePay
                  style={{
                    ApplePayButtonStyle: "white",
                    display: stripeClicked ? "none" : "block",
                  }}
                  onIncompatibilityChange={setIsAppleIncompatible}
                />
                {/*<GooglePay
                  className={isAppleIncompatible ? '' : 'pt-4'}
                  style={{
                    backgroundColor: "white",
                    paddingTop: "0px",
                  }}
                  buttonColor="white"
                  onIncompatibilityChange={setIsGoogleIncompatible}
                />*/}
              </PaymentForm>
            )}
          </TabPane>
          <TabPane tabId="card">
            {clientSecret && (
              <Elements stripe={stripePromise} options={options}>
                <StripePaymentElement
                  urlGlobal={urlGlobal}
                  textosShow={textos}
                  onSuccess={(id) => onSuccess(id, "stripe")}
                  isSetup={false}
                  onReady={onReadyStripe}
                  onError={onError}
                />
              </Elements>
            )}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default MobileCheckoutApp;
