import React, { useState, useEffect } from 'react';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import '../../../node_modules/react-coinbase-commerce/dist/coinbase-commerce-button.css';

export default function CoinbaseButton({ urlGlobal, getHeadersToSend, total, onSuccess, onError }) {
  const [error, setError] = useState('');
  const [checkoutId, setCheckoutId] = useState('');

  useEffect(() => {
    const myHeaders = getHeadersToSend();
    fetch(urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "createCoinbaseCheckout",
        importe: total
      }),
      headers: myHeaders
    }).then((response) => {
      return response.json();
    }).then((result) => {
      if (result.success) {
        setCheckoutId(result.checkout_id);
      }
      else {
        setError(result.msg);
      }
    });
  }, [JSON.stringify({urlGlobal, getHeadersToSend, total})]);
  
  const onChargeSuccess = (response) => {
    onSuccess(response.code);
  };
  
  return (
    <div>
      {
        error ? (
          <p>
            {error}
          </p>
        ) : (
          <CoinbaseCommerceButton 
            checkoutId={checkoutId}
            onChargeSuccess={onChargeSuccess}
            onChargeFailure={onError}
            style={{
              backgroundColor: '#000000',
              color: '#ffffff',
              height: '55px',
              width: '100%',
              borderRadius: '10px',
              fontSize: '18px',
              lineHeight: '55px'
            }} />
        )
      }
    </div>
  );
};
