import React, { memo, useCallback, useEffect, useState } from "react";
// import { payments } from "@square/web-sdk";
import { useForm } from "./context/FormContext";
import { keyframes, styled } from "../../../stitches.config";

const pulse = keyframes({
  "0%, 100%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0.5,
  },
});

const GooglePayContainer = styled("div", {
  animation: `${pulse()} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
  background: "#F3F4F6",
  borderRadius: 4,
  height: 40,
});

export default memo(function GooglePay({
  id = "google-pay-button",
  onIncompatibilityChange = () => {},
  buttonColor,
  buttonSizeMode = 'fill',
  buttonType = 'long',
  ...props
}) {
  const {
    createPaymentRequest,
    cardTokenizeResponseReceived,
    addMethod,
    removeMethod,
    payments,
    addPendingCount,
    decreasePendingCount,
  } = useForm();
  const [googlePay, setGooglePay] = useState(null);

  const options = React.useMemo(() => {
    const baseOptions = {
      buttonColor,
      buttonSizeMode,
      buttonType,
    };

    // if a value from options is undefined delete it from the options object
    return Object.keys(baseOptions).reduce((acc, key) => {
      if (baseOptions[key] !== undefined) {
        acc[key] = baseOptions[key];
      }

      return acc;
    }, {});
  }, [buttonColor, buttonSizeMode, buttonType]);

  const initGooglePay = useCallback(
    async (signal) => {
      try {
        addPendingCount();
        if (payments == null) {
          throw new Error("Payments not initialized");
        }
        console.log("initGooglePay");
        const paymentRequest = payments.paymentRequest(createPaymentRequest());
        const googlePay = await payments.googlePay(paymentRequest);

        if (signal?.aborted) {
          decreasePendingCount();
          return;
        }

        await googlePay?.attach(`#${id}`, options);
        decreasePendingCount();
        onIncompatibilityChange(false);
        setGooglePay(googlePay);
        addMethod("google-pay");

        if (signal.aborted) {
          await googlePay?.destroy();
          removeMethod("google-pay");
        }
      } catch (error) {
        console.error(error);
        decreasePendingCount();
        removeMethod("google-pay");
        onIncompatibilityChange(true);
      }
    },
    [createPaymentRequest, setGooglePay, addMethod, removeMethod, payments]
  );

  const handlePayment = useCallback(
    async (event) => {
      try {
        if (googlePay == null) {
          throw new Error("Google Pay is not initialized");
        }
        const result = await googlePay.tokenize();
        if (result.status === "OK") {
          return cardTokenizeResponseReceived(result);
        }
        throw new Error(result.status);
      } catch (error) {}
    },
    [googlePay, cardTokenizeResponseReceived]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    setGooglePay(null);
    initGooglePay(signal);
    return () => {
      console.log("GooglePay cleanup");
      abortController.abort();
      removeMethod("google-pay");
      setGooglePay(null);
    };
  }, [initGooglePay, removeMethod]);

  return (
    <div {...props} id={id} onClick={handlePayment}>
      {/*!googlePay ? <GooglePayContainer /> : null*/}
    </div>
  );
});
