import React, { memo, useCallback, useEffect, useState } from "react";
// import { payments } from "@square/web-sdk";
import { useForm } from "./context/FormContext";
import { styled } from "../../../stitches.config";

const ApplePayContainer = styled("div", {
  ApplePayButtonStyle: "black",
  ApplePayButtonType: "plain",
  cursor: "pointer",
  display: "inline-block",
  height: 48,
  WebkitAppearance: "-apple-pay-button",
  width: "100%",
});

export default memo(function ApplePay({
  id = "apple-pay-button",
  onIncompatibilityChange = () => {},
  ...props
}) {
  const {
    createPaymentRequest,
    cardTokenizeResponseReceived,
    addMethod,
    removeMethod,
    payments,
    addPendingCount,
    decreasePendingCount,
  } = useForm();
  const [applePay, setApplePay] = useState(null);

  const initApplePay = useCallback(
    async (signal) => {
      try {
        addPendingCount();
        if (payments == null) {
          throw new Error("Payments not initialized");
        }
        console.log("initApplePay");
        const paymentRequest = payments.paymentRequest(createPaymentRequest());
        const applePay = await payments.applePay(paymentRequest);

        if (signal?.aborted) {
          decreasePendingCount();
          return;
        }

        onIncompatibilityChange(false);
        setApplePay(applePay);
        decreasePendingCount();
        addMethod("apple-pay");

        if (signal.aborted) {
          await applePay?.destroy();
          removeMethod("apple-pay");
        }
      } catch (error) {
        console.error(error);
        decreasePendingCount();
        removeMethod("apple-pay");
        onIncompatibilityChange(true);
      }
    },
    [createPaymentRequest, setApplePay, addMethod, removeMethod, payments]
  );

  const handlePayment = useCallback(
    async (event) => {
      try {
        if (applePay == null) {
          throw new Error("Apple Pay is not initialized");
        }
        const result = await applePay.tokenize();
        if (result.status === "OK") {
          return cardTokenizeResponseReceived(result);
        }
        throw new Error(result.status);
      } catch (error) {}
    },
    [applePay, cardTokenizeResponseReceived]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    setApplePay(null);
    initApplePay(signal);
    return () => {
      console.log("ApplePay cleanup");
      abortController.abort();
      removeMethod("apple-pay");
      setApplePay(null);
    };
  }, [initApplePay, removeMethod]);

  return (
    <ApplePayContainer
      {...props}
      onClick={handlePayment}
      css={{
        display: applePay ? "block" : "none",
        opacity: applePay ? 1 : 0.5,
        pointerEvents: applePay ? "auto" : "none",
        visibility: applePay ? "visible" : "hidden",
      }}
      id={id}
    ></ApplePayContainer>
  );
});
