import React, { useState, useEffect, useCallback } from "react";
import PaymentForm from "./Square/PaymentForm";
import ApplePay from "./Square/ApplePay";
import GooglePay from "./Square/GooglePay";

export default function SquareForm({ total, onSuccess }) {
  const [isAppleIncompatible, setIsAppleIncompatible] = useState(false);
  
  const createPaymentRequest = useCallback(
    () => ({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: `${total}`,
        label: "Total",
      },
    }),
    [total]
  );

  const cardTokenizeResponseReceived = ({ token }) => {
    onSuccess(token);
  };

  return (
    <div>
      <PaymentForm
        key={`payment-form-${total}`}
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
        createPaymentRequest={createPaymentRequest}
        cardTokenizeResponseReceived={cardTokenizeResponseReceived}
      >
        <ApplePay onIncompatibilityChange={setIsAppleIncompatible}/>
        <GooglePay className={isAppleIncompatible ? '' : 'pt-4'} />
      </PaymentForm>
    </div>
  );
}
